import React from 'react';

import _Hero from './_HeroA';

import styles from './styles/ProHeroA.module.scss';

export interface ProHeroAProps {
  className?: string;
}

export const ProHeroA: React.FC<ProHeroAProps> = ({
  className,
}: ProHeroAProps) => {
  return (
    <div className={`${styles['pro-heroA']} ${className}`}>
      <_Hero />
    </div>
  );
};

export default ProHeroA;
