import React from 'react';
import Image from 'next/image';

import { PUBLIC_ASSETS_PATH } from 'constants/paths';

import styles from './styles/DyrtAlertLandingPage.module.scss';

export interface _PressSectionProps {
  className?: string;
}

const pressLinks = [
  {
    publisher: 'New York Times',
    link: 'https://www.nytimes.com/2021/04/24/travel/camping-campsite-reserve-reservation.html',
    logo: 'assets/images/about-us/Logo_NYT.png',
    className: 'nyt-logo',
    height: 28,
    width: 194,
  },
  {
    publisher: 'CNBC',
    link: 'https://www.cnbc.com/video/2021/09/07/next-frontier-for-subscription-services-outdoor-camping-apps.html',
    logo: 'assets/images/about-us/Logo_CNBC.png',
    className: 'cnbc-logo',
    height: 77,
    width: 97,
  },
  {
    publisher: 'Tech Crunch',
    link: 'https://techcrunch.com/2021/08/17/taking-consumer-subscription-software-to-the-great-outdoors/',
    logo: 'assets/images/about-us/Logo_TechCrunch.png',
    className: 'tc-logo',
    height: 27,
    width: 189,
  },
  {
    publisher: 'NPR',
    link: 'https://www.npr.org/2020/07/22/894312084/new-to-camping-heres-how-to-get-started',
    logo: 'assets/images/about-us/Logo_NPR.png',
    className: 'npr-logo',
    height: 32,
    width: 94,
  },
  {
    publisher: 'Lonely Planet',
    link: 'https://www.lonelyplanet.com/articles/camping-apps-websites',
    logo: 'assets/images/about-us/Logo_LonelyPlanet.png',
    className: 'lp-logo',
    height: 88,
    width: 178,
  },
  {
    publisher: 'National Geographic',
    link: 'https://www.nationalgeographic.com/travel/article/how-to-go-camping-during-covid',
    logo: 'assets/images/about-us/Logo_NatGeo.png',
    className: 'ng-logo',
    height: 33,
    width: 95,
  },
];

export const _PressSection: React.FC<_PressSectionProps> = ({
  className,
}: _PressSectionProps) => {
  return (
    <div className={`${styles['press-section']} ${className}`}>
      <h2 className={styles['press-section__title']}>FEATURED IN</h2>
      <div className={styles['press-section__logos']}>
        {pressLinks.map((pressLink) => {
          const imageUrl = `${PUBLIC_ASSETS_PATH}/${pressLink.logo}`;
          return (
            <a
              href={pressLink.link}
              target="_blank"
              rel="noreferrer"
              key={pressLink.publisher}
            >
              <div className={styles['press-section__logo-container']}>
                <Image
                  className={styles['press-section__logo']}
                  src={imageUrl}
                  alt={pressLink.publisher}
                  height={pressLink.height}
                  width={pressLink.width}
                />
              </div>
            </a>
          );
        })}
      </div>
    </div>
  );
};

export default _PressSection;
