import React from 'react';
import { useRouter } from 'next/router';
import * as amplitude from '@amplitude/analytics-browser';

import { PAGE_NAMES, PRO_FEATURE_PAGE_VIEW } from 'constants/amplitude';

import useMountEffect from 'hooks/useMountEffect';

import AppPage from 'components/AppPage/AppPage';
import ProFeatureCards from 'components/ProFeatureCards/ProFeatureCards';
import ProHeroA from 'components/ProHero/ProHeroA/ProHeroA';
import ProSignUpAdArea from 'components/ProSignUpAdArea/ProSignUpAdArea';

const Pro: React.FC = () => {
  const router = useRouter();

  useMountEffect(() => {
    amplitude.track(PRO_FEATURE_PAGE_VIEW, {
      'page name': PAGE_NAMES[router.pathname as PageKeys],
    });
  });

  return (
    <AppPage
      pageTitle="The Dyrt PRO: Camping maps, discounts & reservations at sold-out sites"
      socialDescription="Try PRO for free today! Get access to our 1,000+ discount campground network, explore 5,000+ free dispersed campsites, and reserve sold-out campgrounds."
      noPadding
      content={
        <>
          <ProHeroA />
          <ProFeatureCards />
          <ProSignUpAdArea />
        </>
      }
    />
  );
};

export default Pro;
