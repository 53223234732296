import { PUBLIC_ASSETS_PATH } from 'constants/paths';

export const PRO_FEATURE_CARDS = [
  {
    name: 'free-camping',
    image: `${PUBLIC_ASSETS_PATH}/assets/images/pro/benefit-cards/benefits_free_camping@3x.jpg`,
    heading: '5,000 all drive-in accessible free camping locations.',
    linkText: 'View Free Camping',
    altText: 'Map with location pins',
  },
  {
    name: 'offline',
    image: `${PUBLIC_ASSETS_PATH}/assets/images/pro/benefit-cards/benefits_offline@3x.jpg`,
    heading: 'Download all 50,000+ campgrounds and offline maps.',
    linkText: 'Get the App',
    altText: 'Mountain scenery.',
  },
  {
    name: 'booking',
    image: `${PUBLIC_ASSETS_PATH}/assets/images/pro/benefit-cards/benefits_booking@3x.jpg`,
    heading: 'No extra booking fees when you book on The Dyrt.',
    linkText: 'Find and Book like a PRO',
    altText: 'Map with location pins',
  },
  {
    name: 'stations',
    image: `${PUBLIC_ASSETS_PATH}/assets/images/pro/benefit-cards/benefits_pdw@3x.jpg`,
    heading: '19,000 overnight parking spots, dump stations + water stations.',
    linkText: 'Unlock Parking, Dump, + Water',
    proLinkText: 'View Parking, Dump, + Water',
    altText: 'Park with pins.',
  },
  {
    name: 'discounts',
    image: `${PUBLIC_ASSETS_PATH}/assets/images/pro/benefit-cards/benefits_discounts@3x.jpg`,
    heading: '1,000 campgrounds with up to 40% off.',
    linkText: 'See PRO Discounts',
    altText: 'Outdoor camper.',
  },
  {
    name: 'alerts',
    image: `${PUBLIC_ASSETS_PATH}/assets/images/pro/benefit-cards/benefits_alerts@3x.jpg`,
    heading: 'Get reservations at sold out campgrounds.',
    linkText: 'Create a Dyrt Alert',
    altText: 'The Dyrt logo phone',
  },
  {
    name: 'maps',
    image: `${PUBLIC_ASSETS_PATH}/assets/images/pro/benefit-cards/benefits_map_layers@3x.jpg`,
    heading: 'Find camping with BLM, USFS and Cell Service maps.',
    linkText: 'View PRO Maps',
    altText: 'Map with location pins',
  },
  {
    name: 'trips',
    image: `${PUBLIC_ASSETS_PATH}/assets/images/pro/benefit-cards/benefits_trips@3x.jpg`,
    heading: 'Find the best campgrounds along your driving route.',
    linkText: 'Create a Road Trip Map',
    altText: 'Outdoor camping van.',
  },
];
