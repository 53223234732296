import React from 'react';
import Image from 'next/image';
import * as amplitude from '@amplitude/analytics-browser';

import { FEATURE_CARD_CLICK } from 'constants/amplitude';
import { PRO_FEATURE_CARDS } from 'constants/proFeatureCards';

import { useUser } from 'contexts/currentUser';

import getNavLink from 'utils/getNavLink';

import useAmplitudePageName from 'hooks/useAmplitudePageName';

import AppIcon from 'components/AppIcon/AppIcon';
import AppLink from 'components/AppLink/AppLink';

import styles from './styles/ProFeatureCards.module.scss';

const APP_STORE_LINK = process.env.NEXT_PUBLIC_APP_STORE_LINK as string;

export const ProFeatureCards: React.FC = () => {
  const currentUserContext = useUser();
  const isPro = !!currentUserContext?.user?.pro;
  const pageName = useAmplitudePageName();

  // Adds correct filter params per tile.
  const getLinkPath = (name: string) => {
    switch (name) {
      case 'discounts':
        const searchDiscountFilter = new URL(
          `${process.env.NEXT_PUBLIC_NEXT_HOST}/search?showFilters=partner`
        );
        const { searchParams: discountParams } = searchDiscountFilter;
        discountParams.append(
          'filters',
          JSON.stringify({
            partner: 'all',
          })
        );
        return searchDiscountFilter.toString();
      case 'booking':
      case 'free-camping':
        const searchURLFilters = new URL(
          `${process.env.NEXT_PUBLIC_NEXT_HOST}/search?showFilters=free_or_dispersed`
        );
        const { searchParams: params } = searchURLFilters;
        if (name === 'booking') {
          params.append(
            'filters',
            JSON.stringify({
              categories: 'instant,request',
            })
          );
        } else if (name === 'free-camping') {
          params.append(
            'filters',
            JSON.stringify({
              free_or_dispersed: 'free_or_dispersed',
            })
          );
        } else {
          ('/search');
        }
        return searchURLFilters.toString();
        break;
      case 'maps':
        const searchURLMaps = new URL(
          `${process.env.NEXT_PUBLIC_NEXT_HOST}/search?showMaps=1`
        );
        return searchURLMaps.toString();
        break;
      case 'trips':
        const trips = new URL(`${process.env.NEXT_PUBLIC_EMBER_HOST}/trips`);
        return trips.toString();
        break;
      case 'alerts':
        return '/alerts';
        break;
      case 'chat':
        return 'https://support.thedyrt.com';
        break;
      case 'offline':
        return APP_STORE_LINK;
        break;
      case 'stations':
        const searchURLStations = new URL(
          `${process.env.NEXT_PUBLIC_NEXT_HOST}/search?showFilters=pro_categories`
        );
        const { searchParams: stationParams } = searchURLStations;

        stationParams.append(
          'filters',
          JSON.stringify({
            pro_categories: 'dump,overnight_parking,water',
          })
        );

        return searchURLStations.toString();
        break;
      default:
        return '/search';
        break;
    }
  };

  return (
    <div className={styles['pro-features']}>
      <div className={styles['pro-features__container']}>
        {PRO_FEATURE_CARDS.map((card) => (
          <AppLink
            href={!isPro ? getNavLink('/pro/checkout') : getLinkPath(card.name)}
            key={card.name}
          >
            <a
              className={styles['pro-features__link']}
              onClick={() => {
                amplitude.track(FEATURE_CARD_CLICK, {
                  'page name': pageName,
                  'button name': card.linkText,
                  'is pro user': isPro,
                });
              }}
              data-testid={`beneifts-features-card-${card.name}`}
            >
              <div className={styles['pro-features__card']}>
                <div className={styles['pro-features__image-container']}>
                  <Image
                    className={styles['pro-features__image']}
                    src={card.image}
                    alt={card.altText}
                    width={355}
                    height={165}
                  />
                </div>
                <div className={styles['pro-features__info']}>
                  <h3 className={styles['pro-features__heading']}>
                    {card.heading}
                  </h3>
                  <div className={styles['pro-features__cta']}>
                    {isPro && card.name === 'stations'
                      ? card.proLinkText
                      : card.linkText}
                    <AppIcon
                      classNameSvg={[`${styles['pro-features__arrow']}`]}
                      icon="arrow_right"
                    />
                  </div>
                </div>
                {(card.name === 'stations' || card.name === 'free-camping') && (
                  <span
                    className={styles['pro-features__new']}
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                    }}
                  >
                    NEW!
                  </span>
                )}
              </div>
            </a>
          </AppLink>
        ))}
      </div>
    </div>
  );
};

export default ProFeatureCards;
