import React from 'react';
import Image from 'next/image';

import { PUBLIC_ASSETS_PATH } from 'constants/paths';

import styles from './styles/DyrtAlertLandingPage.module.scss';

export interface _RatingsSectionProps {
  className?: string;
}

export const _RatingsSection: React.FC<_RatingsSectionProps> = ({
  className,
}: _RatingsSectionProps) => {
  return (
    <div className={`${styles['ratings-section']} ${className}`}>
      <h2 className={styles['ratings-section__title']}>
        Campers Love The Dyrt
      </h2>
      <div className={styles['ratings-section__interior']}>
        <div className={styles['ratings-section__quotes']}>
          <figure className={styles['ratings-section__figure']}>
            <blockquote className={styles['ratings-section__blockquote']}>
              Clear, precise info when you need it
            </blockquote>
          </figure>
          <figure className={styles['ratings-section__figure']}>
            <blockquote className={styles['ratings-section__blockquote']}>
              Really helps out a lot when looking for camp spots
            </blockquote>
          </figure>
        </div>
        <div className={styles['ratings-section__images']}>
          <Image
            className={styles['ratings-section__images-app-store']}
            src={`${PUBLIC_ASSETS_PATH}/assets/images/dyrt-alerts/App-Store@2x.png`}
            alt="Apple App Store Featured App"
            width={190}
            height={76}
          />
          <Image
            className={styles['ratings-section__images-ratings']}
            src={`${PUBLIC_ASSETS_PATH}/assets/images/dyrt-alerts/Ratings@2x.png`}
            alt="4.8 out of 5 stars and over 80,000 ratings"
            width={280}
            height={30}
          />
        </div>
      </div>
    </div>
  );
};

export default _RatingsSection;
