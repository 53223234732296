import React from 'react';
import * as amplitude from '@amplitude/analytics-browser';

import { PRO_CTA_CLICK } from 'constants/amplitude';

import { useUser } from 'contexts/currentUser';

import useAmplitudePageName from 'hooks/useAmplitudePageName';
import { useRelativeAuthLinks } from 'hooks/useAuthRedirect';

import AppButtonLink from 'components/AppButton/AppButtonLink';
import AppDivider from 'components/AppDivider/AppDivider';
import AppLink from 'components/AppLink/AppLink';
import _PressSection from 'components/DyrtAlertLandingPage/_PressSection';
import _RatingsSection from 'components/DyrtAlertLandingPage/_RatingsSection';

import styles from './styles/ProSignUpAdArea.module.scss';

export const ProSignUpAdArea: React.FC = () => {
  const currentUserContext = useUser();
  const isPro = !!currentUserContext?.user?.pro;
  const { signInLink } = useRelativeAuthLinks();
  const pageName = useAmplitudePageName();

  return !isPro ? (
    <>
      <_RatingsSection />
      <_PressSection />
      <AppDivider className={styles['signUp__freeAccess-divider']} />
      <div className={styles['signUp__freeAccess-container']}>
        <h3 className={styles['signUp__freeAccess-heading']}>
          get free access now
        </h3>
        <h3 className={styles['signUp__freeAccess-subHeading']}>
          Go PRO, create a lifetime memory this year:
        </h3>
        <ul className={styles['signUp__freeAccess-list']}>
          <li>$0 extra booking fees</li>
          <li>Discounts at 1,000 campgrounds</li>
          <li>5,000 free camping locations</li>
          <li>+ more with The Dyrt PRO</li>
        </ul>
        <AppButtonLink
          className={styles['signUp__freeAccess-cta']}
          label="Try PRO for Free"
          path="/pro/checkout"
          dataEvent="pro_page_footer_click_try_pro"
          pro="gradient"
          onClick={() => {
            amplitude.track(PRO_CTA_CLICK, {
              'button name': 'Bottom',
              'page name': pageName,
            });
          }}
        />
        {!currentUserContext?.user && (
          <p className={styles['signUp__freeAccess-alreadyPro']}>
            Already PRO?{' '}
            <AppLink href={signInLink}>
              <a
                className={styles['signUp__freeAccess-alreadyPro-link']}
                data-event="pro_page_footer__Sign-In"
              >
                Sign In
              </a>
            </AppLink>
          </p>
        )}
      </div>
    </>
  ) : null;
};

export default ProSignUpAdArea;
