import React from 'react';
import Image from 'next/image';
import * as amplitude from '@amplitude/analytics-browser';
import classNames from 'classnames/bind';

import { PRO_CTA_CLICK } from 'constants/amplitude';
import { PUBLIC_ASSETS_PATH } from 'constants/paths';

import { useUser } from 'contexts/currentUser';

import useAmplitudePageName from 'hooks/useAmplitudePageName';

import AppAvatar from 'components/AppAvatar/AppAvatar';
import AppButtonLink from 'components/AppButton/AppButtonLink';

import styles from './styles/ProHeroA.module.scss';

const cx = classNames.bind(styles);

export interface _HeroAProps {
  className?: string;
}

export const _HeroA: React.FC<_HeroAProps> = ({ className }: _HeroAProps) => {
  const currentUserContext = useUser();
  const userIsPro = currentUserContext?.user?.pro;
  const pageName = useAmplitudePageName();

  const contentClassNames = cx({
    'heroA__content-heading': true,
    'heroA__content-heading--pro': !!userIsPro,
  });

  return (
    <div className={`${styles['heroA']} ${className}`}>
      <div className={styles['heroA__heroContainer']}>
        <div className={styles['heroA__hero']}>
          <div className={styles['heroA__hero-image']}>
            <Image
              src={`${PUBLIC_ASSETS_PATH}/assets/images/pro/header/benefits_header@2x.jpg`}
              alt="The Dyrt Pro Hero"
              layout={'fill'}
              objectFit="cover"
            />
          </div>
          <div className={styles['heroA__hero-image--mobile']}>
            <Image
              src={`${PUBLIC_ASSETS_PATH}/assets/images/pro/header/benefits_header_mobile.jpg`}
              alt="The Dyrt Pro Hero"
              layout={'fill'}
              objectFit="cover"
            />
          </div>
          <h1 className={styles['heroA__title-is-pro']}>
            {userIsPro && currentUserContext?.user
              ? 'Your PRO Membership Benefits'
              : 'PRO Membership Benefits'}
          </h1>
          {userIsPro && currentUserContext?.user && (
            <AppAvatar
              className={styles['heroA__avatar']}
              user={currentUserContext.user}
              size="lg"
              hideInfo
              imageClassName={styles['heroA__avatarImage']}
            />
          )}
        </div>
      </div>
      <div className={styles['heroA__content']}>
        <h1 className={contentClassNames}>
          {userIsPro ? (
            'Thank you for being a PRO member of The Dyrt!'
          ) : (
            <>
              Every 4 minutes a camper becomes a PRO member. 
              <br className={styles['heroA__lineBreak']} />
              Find out why with your free trial.
            </>
          )}
        </h1>
        {userIsPro && (
          <>
            <p className={styles['heroA__content-desc']}>
              PRO features help you camp better, save money and create lifetime
              camping memories. <br className={styles['heroA__lineBreak']} />
              If you have any questions about PRO, just ask{' '}
              <a
                href="https://support.thedyrt.com/hc/en-us"
                className={styles['heroA__link-help']}
              >
                PRO Support
              </a>
              . Enjoy!
            </p>
          </>
        )}
        {!userIsPro && (
          <AppButtonLink
            className={styles['heroA__cta']}
            label="Try PRO for Free"
            icon="arrow_right"
            pro="gradient"
            path={`/pro/checkout`}
            dataEvent={'click_pro_cta_pro_landing_heroA_event'}
            onClick={() => {
              amplitude.track(PRO_CTA_CLICK, {
                'page name': pageName,
                'button name': 'Top',
              });
            }}
          />
        )}
      </div>
    </div>
  );
};

export default _HeroA;
